import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    inject,
} from '@angular/core';

import { ofType } from '@ngrx/effects';
import { ActionsSubject } from '@ngrx/store';
import { BehaviorSubject, Observable, forkJoin, of } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';

import {
    Action,
    FieldDefinition,
    FieldDefinitionOption,
    FormConditions,
    FormDataResult,
    FormDefinition,
    FormElementLayoutDefinition,
    FormFieldType,
    FormFunctionResult,
    FormSectionLayoutDefinition,
    Trigger,
} from '@wdx/clmi/api-models';
import { FormElementType, WdxDestroyClass } from '@wdx/shared/utils';

import {
    DynamicFormsService,
    FormLockService,
    FormPendingChangesService,
    FormSummaryLayoutService,
    FormTriggersService,
    IFormDynamicData,
    dynamicFormsActions,
} from '@wdx/shared/infrastructure/form-framework';
import { FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE } from '../../../../../constants/form-summary.constants';
import { IClmiClick } from '../../../../../interfaces/clmi-click.interface';
import { FormSummaryPendingChangesService } from '../../services/form-pending-changes/form-pending-changes.service';
import { FormSummaryHistoricService } from '../../services/form-summary-historic/form-summary-historic.service';

@Component({
    selector: 'organism-form-summary',
    templateUrl: './organism-form-summary.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormPendingChangesService],
})
export class OrganismFormSummaryComponent
    extends WdxDestroyClass
    implements OnInit, OnChanges
{
    @Input() formId: string;
    @Input() appId: string;
    @Input() caseId: string;
    @Input() customEndpoint: string;

    // When true, an external action is in process that will effect the form data
    @Input() isPreLoading: boolean;

    @Input() set entityId(entityId: string) {
        if (!entityId) {
            return;
        }
        this._entityId = entityId;
        this.render(entityId);
    }

    get entityId() {
        return this._entityId;
    }

    @Output() checkIfFormLocked = new EventEmitter<boolean>();
    @Output() clmiOnClick = new EventEmitter<IClmiClick>();

    get summaryInfoCards$(): Observable<FieldDefinitionOption[]> {
        return this.formSummaryLayoutService.summaryInfoCards$;
    }

    data$ = new BehaviorSubject<Record<string, any>>(null);
    formDetails$ = new BehaviorSubject<FormDataResult>(null);
    layoutDefinition$ = new BehaviorSubject<FormElementLayoutDefinition>(null);
    definition$ = new BehaviorSubject<FormDefinition>(null);
    isLoading$: Observable<boolean>;
    subFormData: any;
    subFormData$ = new BehaviorSubject(null);
    FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE = FORM_SUMMARY_CHANGE_OF_CIRCUMSTANCE;

    _entityId: string;

    public formSummaryPendingChangesService = inject(
        FormSummaryPendingChangesService
    );
    private actionsSubject$ = inject(ActionsSubject);
    private dynamicFormsService = inject(DynamicFormsService);
    private dynamicDataService = inject(IFormDynamicData);
    private formLockService = inject(FormLockService);
    private formTriggersService = inject(FormTriggersService);
    private formSummaryLayoutService = inject(FormSummaryLayoutService);
    private formSummaryHistoricService = inject(FormSummaryHistoricService);

    ngOnInit(): void {
        if (this.customEndpoint) {
            this.render(null);
        }

        if (!this.appId) {
            this.actionsSubject$
                .pipe(
                    takeUntil(this.destroyed$),
                    ofType(
                        ...dynamicFormsActions.formDataCreateAndUpdateSuccessActions
                    ),
                    filter((action: any) => action.formId === this.formId)
                )
                .subscribe((action: any) => {
                    this.render(action.response.entityId);
                });
        }
    }

    /**
     * When 'isPreLoading' has tranisitioned from true to false, re-render
     */
    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.isPreLoading?.previousValue &&
            !changes.isPreLoading?.currentValue
        ) {
            this.render(this.entityId);
        }
    }

    render(entityId: string): void {
        this.data$.next(null);

        this.dynamicDataService
            .getFormLayoutAndDefinition(this.formId)
            .pipe(
                take(1),
                switchMap(({ definition, layoutAndDefinitions }) =>
                    this.getFormData(entityId, definition).pipe(
                        takeUntil(this.destroyed$),
                        filter((formData) => Boolean(formData)),
                        switchMap((formData) =>
                            this.getInitialisationFunctions(
                                definition,
                                formData
                            )
                        ),
                        map(({ formData, formFunctionResults }) => ({
                            layoutAndDefinition: {
                                sectionLayoutDefinitions:
                                    this.getLayoutAndDefinitionsWithConditions(
                                        definition,
                                        layoutAndDefinitions,
                                        formFunctionResults,
                                        formData
                                    ),
                            },
                            data: formData.data,
                            formDetails: formData,
                            definition: definition,
                        }))
                    )
                )
            )
            .subscribe(
                ({ layoutAndDefinition, data, formDetails, definition }) => {
                    this.formSummaryPendingChangesService.updatePendingData(
                        layoutAndDefinition,
                        formDetails
                    );

                    this.layoutDefinition$.next({
                        ...layoutAndDefinition,
                        name: '',
                        elementType: FormElementType.Field,
                    });
                    this.definition$.next(definition);
                    this.data$.next(data);
                    this.formDetails$.next(formDetails);
                    this.formSummaryLayoutService.getSummaryInfoCards(
                        definition,
                        data
                    );
                    this.checkIfFormLocked.emit(
                        this.formLockService.hasFullFormLock(formDetails.lock)
                    );
                }
            );
    }

    getFormData(entityId: string, definition: FormDefinition) {
        if (
            this.formSummaryHistoricService.loadHistoricData &&
            this.formSummaryHistoricService.formUuid
        ) {
            return this.dynamicFormsService.getAppFormDataHistorical(
                this.formId,
                entityId,
                this.formSummaryHistoricService.formUuid
            );
        }

        if (this.customEndpoint) {
            return this.dynamicFormsService.getFormDataForCustomEndpoint(
                this.customEndpoint
            );
        }

        return this.appId
            ? this.dynamicFormsService.getAppFormData(this.appId)
            : this.dynamicFormsService.getFormData(
                  this.formId,
                  entityId,
                  definition.endpointPath
              );
    }

    getInitialisationFunctions(definition: FormDefinition, formData: any) {
        if (definition.conditions?.Initialisation?.functions?.length) {
            return forkJoin(
                definition.conditions?.Initialisation?.functions?.map((fn) =>
                    this.getApiFunctionTrigger(fn.name, formData.data)
                )
            ).pipe(
                map((formFunctionResults: FormFunctionResult[]) => ({
                    formData,
                    formFunctionResults: formFunctionResults.filter(
                        (formFunctionResult) => Boolean(formFunctionResult)
                    ),
                }))
            );
        }
        return of({ formData, formFunctionResults: [] });
    }

    getLayoutAndDefinitionsWithConditions(
        definition: FormDefinition,
        layoutAndDefinitions: FormSectionLayoutDefinition[],
        formFunctionResults: FormFunctionResult[],
        formData: FormDataResult
    ) {
        const actionSources = this.getActionSources(
            definition.conditions,
            formFunctionResults,
            formData,
            layoutAndDefinitions
        );

        return layoutAndDefinitions?.map((sectionLayoutAndDefinition) => {
            const updateSection = actionSources?.updateSections?.find(
                (updateSection) =>
                    updateSection.name === sectionLayoutAndDefinition.name
            );
            return {
                ...sectionLayoutAndDefinition,
                isHidden:
                    sectionLayoutAndDefinition.isHidden ||
                    sectionLayoutAndDefinition.elementLayoutDefinitions.every(
                        (def: FieldDefinition) => Boolean(def.summaryLevel)
                    ),
                elementLayoutDefinitions:
                    sectionLayoutAndDefinition.elementLayoutDefinitions.map(
                        (elementLayoutDefinition: any) => {
                            let result = elementLayoutDefinition;
                            const isFieldTypeArray =
                                elementLayoutDefinition.fieldType ===
                                FormFieldType.Array;

                            const schemaIndex = definition.schema.findIndex(
                                (schemaItem) =>
                                    schemaItem.name ===
                                    elementLayoutDefinition.name
                            );
                            if (schemaIndex === -1) {
                                return {
                                    ...elementLayoutDefinition,
                                    isHidden: true,
                                };
                            }

                            if (isFieldTypeArray) {
                                const DEFAULT_ELEMENT_DEFINITION = [
                                    ...(elementLayoutDefinition
                                        ?.sectionLayoutDefinitions[0]
                                        ?.elementLayoutDefinitions as []),
                                ];

                                const MAX =
                                    formData?.data[elementLayoutDefinition.name]
                                        ?.length;

                                for (let i = 0; i < MAX; i++) {
                                    if (
                                        !this.subFormData?.[
                                            elementLayoutDefinition.name
                                        ]
                                    ) {
                                        this.subFormData = {
                                            ...this.subFormData,
                                            [elementLayoutDefinition.name]: {
                                                [i]: DEFAULT_ELEMENT_DEFINITION,
                                            },
                                        };
                                    } else {
                                        this.subFormData[
                                            elementLayoutDefinition.name
                                        ] = {
                                            ...this.subFormData[
                                                elementLayoutDefinition.name
                                            ],
                                            [i]: DEFAULT_ELEMENT_DEFINITION,
                                        };
                                    }
                                }
                            }

                            if (actionSources?.updateFields) {
                                const updateFieldIndex =
                                    actionSources.updateFields
                                        .map((updateField) => updateField.name)
                                        .lastIndexOf(
                                            elementLayoutDefinition.name
                                        );

                                if (updateFieldIndex !== -1) {
                                    let updateField =
                                        actionSources?.updateFields[
                                            updateFieldIndex
                                        ];

                                    if (updateField?.children) {
                                        updateField = {
                                            ...updateField,
                                            ...elementLayoutDefinition,
                                        };

                                        const UPDATE_DATA: any =
                                            actionSources.updateFields.filter(
                                                (updateField) =>
                                                    updateField.name ===
                                                    elementLayoutDefinition.name
                                            );

                                        const update = (acc, cur) => {
                                            const ITEMS = cur.children;

                                            ITEMS?.forEach((item, i) => {
                                                item.map((field) => {
                                                    const DATA =
                                                        acc[i] || (acc[i] = []);
                                                    const FIELD_INDEX =
                                                        DATA.findIndex(
                                                            (fieldData) =>
                                                                fieldData.name ===
                                                                field.name
                                                        );

                                                    if (FIELD_INDEX >= 0) {
                                                        DATA[FIELD_INDEX] = {
                                                            ...DATA[
                                                                FIELD_INDEX
                                                            ],
                                                            ...field,
                                                        };
                                                    } else {
                                                        DATA.push(field);
                                                    }
                                                });
                                            });

                                            return acc;
                                        };

                                        const sumWithInitial =
                                            UPDATE_DATA.reduce(
                                                (acc, cur) => update(acc, cur),
                                                {}
                                            );

                                        const SUB_FORM = {
                                            ...this.subFormData[
                                                elementLayoutDefinition.name
                                            ],
                                        };

                                        Object.keys(sumWithInitial).forEach(
                                            (rowIndex) => {
                                                const ROW_INFO =
                                                    sumWithInitial[rowIndex];

                                                ROW_INFO.forEach(
                                                    (updateItem) => {
                                                        const itemToUpdateIndex =
                                                            SUB_FORM[
                                                                rowIndex
                                                            ].findIndex(
                                                                (
                                                                    subFormDataItem
                                                                ) =>
                                                                    subFormDataItem.name ===
                                                                    updateItem.name
                                                            );

                                                        const SUB_FORM_ARRAY = [
                                                            ...SUB_FORM[
                                                                rowIndex
                                                            ],
                                                        ];

                                                        SUB_FORM_ARRAY[
                                                            itemToUpdateIndex
                                                        ] = {
                                                            ...SUB_FORM_ARRAY[
                                                                itemToUpdateIndex
                                                            ],
                                                            ...(updateItem
                                                                .options
                                                                ?.length >
                                                                0 && {
                                                                options:
                                                                    updateItem.options,
                                                            }),
                                                            isHidden:
                                                                updateItem.isHidden,
                                                            isDisabled:
                                                                updateItem.isDisabled,
                                                            isRequired:
                                                                updateItem.isRequired,
                                                        };

                                                        SUB_FORM[rowIndex] =
                                                            SUB_FORM_ARRAY;

                                                        this.subFormData = {
                                                            ...this.subFormData,
                                                            [elementLayoutDefinition.name]:
                                                                SUB_FORM,
                                                        };
                                                    }
                                                );
                                            }
                                        );

                                        result = {
                                            ...result,
                                        };
                                    }

                                    if (!updateField?.children) {
                                        result = {
                                            ...result,
                                            ...(updateField.options?.length >
                                                0 && {
                                                options: updateField.options,
                                            }),
                                            isHidden: updateField.isHidden,
                                            isDisabled: updateField.isDisabled,
                                            isRequired: updateField.isRequired,
                                        };
                                    }
                                }
                            }

                            if (actionSources?.updateLabels) {
                                const updateLabelIndex =
                                    actionSources.updateLabels
                                        .map((field) => field.name)
                                        .lastIndexOf(
                                            elementLayoutDefinition.name
                                        );
                                if (updateLabelIndex !== -1) {
                                    const updateLabel =
                                        actionSources?.updateLabels[
                                            updateLabelIndex
                                        ];
                                    result = {
                                        ...result,
                                        label: updateLabel.label,
                                    };
                                }
                            }

                            this.subFormData$.next(this.subFormData);

                            return result;
                        }
                    ),
                ...(updateSection && { ...updateSection }),
            };
        });
    }

    getApiFunctionTrigger(
        functionName: string,
        formData: any
    ): Observable<FormFunctionResult> {
        return this.dynamicFormsService.getFormFunctionResult(this.formId, {
            functionName,
            formData,
        });
    }

    getActionSources(
        conditions: FormConditions,
        formFunctionResults: FormFunctionResult[],
        formData: FormDataResult,
        layoutAndDefinitions?: FormSectionLayoutDefinition[]
    ) {
        const { data, context } = formData;

        const updateFields = formFunctionResults.reduce((prev, current) => {
            const fields = Object.values(current?.schemaChanges).map(
                (field: FieldDefinition) => {
                    return {
                        name: field.name,
                        isRequired: field.isRequired || false,
                        options: field.options || [],
                    };
                }
            );
            return [...prev, ...fields];
        }, []);

        return conditions?.triggers?.reduce(
            (prev, current) => {
                const USE_CONTEXT =
                    this.formTriggersService.useContext(current);

                const NAMES = current.field.split('.');

                const fieldValue = USE_CONTEXT
                    ? this.formTriggersService.getContextTriggerValue(
                          current,
                          context
                      )
                    : NAMES.length > 1
                    ? data[NAMES[0]]
                    : data[current.field];

                return {
                    updateFields: [
                        ...prev.updateFields,
                        ...this.getActions(
                            current,
                            fieldValue,
                            'updateFields',
                            layoutAndDefinitions
                        ),
                    ],
                    updateLabels: [
                        ...prev.updateLabels,
                        ...this.getActions(current, fieldValue, 'updateLabels'),
                    ],
                    updateSections: [
                        ...prev.updateSections,
                        ...this.getActions(
                            current,
                            fieldValue,
                            'updateSections'
                        ),
                    ],
                };
            },
            {
                updateFields,
                updateLabels: [],
                updateSections: [],
            }
        );
    }

    getActions(
        current: Trigger,
        fieldValue: any,
        action: keyof Action,
        layoutAndDefinitions?: FormSectionLayoutDefinition[]
    ) {
        const NAMES = current.field.split('.');
        let data = [];
        const children = [];

        if (
            NAMES?.length > 1 &&
            Array.isArray(fieldValue) &&
            fieldValue?.length
        ) {
            const FIRST_NAME = NAMES[0];
            const SECOND_NAME = NAMES[1];
            let elementLayoutInfo;

            layoutAndDefinitions?.forEach((layoutAndDefinition) => {
                layoutAndDefinition.elementLayoutDefinitions?.forEach(
                    (elementLayoutDefinition) => {
                        if (FIRST_NAME === elementLayoutDefinition.name) {
                            elementLayoutInfo = elementLayoutDefinition;
                        }
                    }
                );
            });

            fieldValue?.map((item, index) => {
                const child_data = this.checkTriggerStateToUse(
                    current,
                    item[SECOND_NAME],
                    action
                );

                child_data.forEach((child) => {
                    if (child?.name) {
                        const UPDATE_FIELD_NAME = child.name.split('.');

                        child = {
                            ...child,
                            name: UPDATE_FIELD_NAME[1],
                        };

                        (children[index] || (children[index] = [])).push(child);
                    }
                });
            });

            if (elementLayoutInfo) {
                data.push({
                    name: FIRST_NAME,
                    ...elementLayoutInfo,
                    children,
                });
            }
        } else {
            data = this.checkTriggerStateToUse(current, fieldValue, action);
        }

        return data;
    }

    checkTriggerStateToUse(current, fieldValue, action) {
        if (!current?.is) {
            return [];
        }

        return current.is.reduce(
            (_, is) =>
                this.formTriggersService.is(is, fieldValue)
                    ? [..._, ...(is.then?.[action] ? is.then[action] : [])]
                    : [..._, ...(is.else?.[action] ? is.else[action] : [])],
            []
        );
    }

    onClickEvent(name: string): void {
        this.clmiOnClick.emit({ name });
    }

    trackBySection(index: number, section: FormSectionLayoutDefinition) {
        return section.name;
    }

    trackByElement(index: number, element: FieldDefinition) {
        return element.name;
    }
}
