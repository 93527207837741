import { Injectable, inject } from '@angular/core';

import {
    FieldDefinition,
    FormFieldType,
    TitleCasePipe,
    WdxDateFormat,
    WdxDateTimeService,
    WdxNumberService,
} from '@wdx/shared/utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import showdown from 'showdown';
import { AddressFormatService } from '../../components/form-controls/form-address-control/services/address-format.service';
import {
    ADDRESS_SUMMARY_TYPES,
    CURRENCY_SUMMARY_TYPES,
    DATE_RANGE_SUMMARY_TYPES,
    DATE_SUMMARY_TYPES,
    DECIMAL_SUMMARY_TYPES,
    INTEGER_SUMMARY_TYPES,
    LOOKUP_SUMMARY_TYPES,
    OPTIONS_SUMMARY_TYPES,
    PHONE_SUMMARY_TYPES,
    RICH_TEXT_SUMMARY_TYPES,
    TEXT_TYPES,
} from '../../constants/summary-types';
import { SubFormHeaderSubLevelType } from '../../interfaces';
import { IFormDynamicData } from '../../interfaces/form-dynamic-data.interface';
import { ExtendedFieldDefinitionOption } from '../../models';
import { FormSummaryValueOptions } from '../../models/form-summary-value.model';
import { FormContextualDataService } from '../form-contextual-data';
import { TelephoneService } from '../telephone';

@Injectable()
export class FormSummaryService {
    private wdxDateTimeService = inject(WdxDateTimeService);
    private wdxNumberService = inject(WdxNumberService);
    private dynamicDataService = inject(IFormDynamicData);
    private telephoneService = inject(TelephoneService);
    private formContextualDataService = inject(FormContextualDataService);
    private addressFormatService = inject(AddressFormatService);

    getStringValue(
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        options?: FormSummaryValueOptions,
        parentData?: any,
        formSchema?: FieldDefinition[]
    ): Observable<string | null> {
        if (!fieldDefinition) {
            return of(null);
        }

        if (TEXT_TYPES.includes(fieldDefinition.fieldType as FormFieldType)) {
            return of(fieldValue);
        }

        if (
            DECIMAL_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            return this.dynamicDataService
                .getMeLocale()
                .pipe(
                    map((locale) =>
                        this.wdxNumberService.formatDecimal(
                            fieldValue,
                            locale.name as string
                        )
                    )
                );
        }

        if (
            INTEGER_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            return this.dynamicDataService
                .getMeLocale()
                .pipe(
                    map((locale) =>
                        this.wdxNumberService.formatNumber(
                            fieldValue,
                            locale.name
                        )
                    )
                );
        }

        if (
            CURRENCY_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            return this.dynamicDataService.getMeLocale().pipe(
                map((locale) =>
                    this.wdxNumberService.formatCurrency(fieldValue?.amount, {
                        locale: locale.name,
                        currencyCode: fieldValue?.isoCode,
                        groupSeparator:
                            locale.numberFormat?.numberGroupSeparator,
                        decimalSeparator:
                            locale.numberFormat?.numberDecimalSeparator,
                    })
                )
            );
        }

        if (
            PHONE_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            if (!fieldValue) {
                return of(null);
            }
            return this.telephoneService.fieldValueToTelephonePattern$(
                fieldValue
            );
        }

        if (
            DATE_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            return of(
                this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                    fieldValue,
                    {
                        format:
                            fieldDefinition.fieldType === FormFieldType.DateTime
                                ? WdxDateFormat.AbsoluteDateTime
                                : WdxDateFormat.AbsoluteDate,
                    }
                )
            );
        }

        if (
            DATE_RANGE_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            const start =
                this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                    fieldValue.start,
                    {
                        format:
                            fieldDefinition.fieldType ===
                            FormFieldType.DateTimeRange
                                ? WdxDateFormat.AbsoluteDateTime
                                : WdxDateFormat.AbsoluteDate,
                    }
                );
            const end = this.wdxDateTimeService.convertDateToViewFriendlyFormat(
                fieldValue.end,
                {
                    format:
                        fieldDefinition.fieldType ===
                        FormFieldType.DateTimeRange
                            ? WdxDateFormat.AbsoluteDateTime
                            : WdxDateFormat.AbsoluteDate,
                }
            );
            return end ? of(`${start} - ${end}`) : of(start);
        }

        if (
            OPTIONS_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            return this.getOptionsValue(
                fieldValue,
                fieldDefinition,
                options,
                parentData,
                formSchema
            ) as Observable<string>;
        }

        if (
            LOOKUP_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            return of(
                Array.isArray(fieldValue)
                    ? fieldValue[0]?.name
                    : fieldValue?.name
            );
        }

        if (
            ADDRESS_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            const address = Object.keys(fieldValue).length
                ? fieldValue
                : parentData?.postalAddresses?.find(
                      (address: any) =>
                          address.addressType &&
                          address.addressType === options?.formData?.addressType
                  )?.address;

            return this.addressFormatService
                .getAddress$(address, true)
                .pipe(map((item) => item.join(', ')));
        }

        if (fieldDefinition.fieldType === FormFieldType.Boolean) {
            return of(fieldValue ? 'Checked' : '');
        }

        /**
         * Rich text fields will either be markdown (as saved from rich text ui) or html (for example email content)
         * If HTML is returned strip out images
         */
        if (
            RICH_TEXT_SUMMARY_TYPES.includes(
                fieldDefinition.fieldType as FormFieldType
            )
        ) {
            const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);
            return isHTML(fieldValue)
                ? of(fieldValue.replace(/<img[^>]*>/g, ''))
                : of(new showdown.Converter().makeHtml(fieldValue));
        }

        return of(fieldValue);
    }

    /**
     * Returns string value from an Option defintition
     */
    getOptionsValue(
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        options?: FormSummaryValueOptions,
        parentData?: any,
        formSchema?: FieldDefinition[]
    ) {
        if (fieldDefinition.options?.length) {
            const fieldValueArray = Array.isArray(fieldValue)
                ? fieldValue
                : [fieldValue];
            return of(
                fieldValueArray
                    .map(
                        (value) =>
                            fieldDefinition.options?.find((option) =>
                                this.dynamicDataService.compareOptionValue(
                                    option.value,
                                    value
                                )
                            )?.label
                    )
                    .join(', ')
            );
        }

        if (fieldDefinition.selectSource) {
            const contextualData =
                this.formContextualDataService.getContextualData(
                    fieldDefinition.contextualValues as string[],
                    options?.formData,
                    parentData,
                    formSchema,
                    fieldDefinition.contextualType
                );

            let optionsProvider: Observable<ExtendedFieldDefinitionOption[]>;

            if (
                contextualData.lookups?.length ||
                contextualData.context?.length
            ) {
                const { context, lookups } = contextualData;
                optionsProvider =
                    this.dynamicDataService.getContextSelectApiSourceOptions(
                        fieldDefinition.selectSource,
                        lookups,
                        context
                    );
            } else {
                const contextualValues =
                    this.formContextualDataService.getContextualResults(
                        contextualData
                    );
                optionsProvider =
                    this.dynamicDataService.getSelectApiSourceOptions(
                        fieldDefinition.selectSource,
                        {
                            ...(contextualValues?.length > 0 && {
                                parent: contextualValues.join(','),
                            }),
                        }
                    );
            }

            return optionsProvider.pipe(
                map((fieldOptions) => {
                    if (Array.isArray(fieldValue)) {
                        return fieldOptions
                            .filter((option) =>
                                fieldValue
                                    .map((fieldValue) =>
                                        fieldValue.toLowerCase()
                                    )
                                    .includes(option.value?.toLowerCase())
                            )
                            .map((options) => options.label)
                            .join(', ');
                    }
                    const matchedOption = fieldOptions.find((option) =>
                        this.dynamicDataService.compareOptionValue(
                            option.value,
                            fieldValue
                        )
                    );
                    if (
                        fieldDefinition.fieldType === FormFieldType.Hierarchical
                    ) {
                        return `${matchedOption?.value} - ${matchedOption?.label}`;
                    }
                    return matchedOption?.label;
                })
            );
        }

        return of(new TitleCasePipe().transform(fieldValue));
    }

    /**
     *
     * @param {SubFormHeaderSubLevelType} subtitles - These are the sub titles to be used
     * @param {any} fieldValue - This is the address control value
     * @param {FieldDefinition} fieldDefinition - This is the FieldDefinition
     * @param {string} fieldName - This is the name of the FieldDefinition
     *
     * @returns {SubFormHeaderSubLevelType}
     */
    overrideSubTitle(
        subtitles: SubFormHeaderSubLevelType,
        fieldValue: any,
        fieldDefinition: FieldDefinition,
        fieldName: string
    ): SubFormHeaderSubLevelType {
        const IS_ADDRESS = ADDRESS_SUMMARY_TYPES.includes(
            fieldDefinition?.fieldType as FormFieldType
        );
        let addressType;

        if (IS_ADDRESS) {
            addressType = fieldDefinition?.options?.find(
                (option) => option.value === fieldValue[fieldName]
            );
        }

        if (!IS_ADDRESS || !addressType) {
            return subtitles;
        }

        const SUB_TITLE = [...subtitles];
        SUB_TITLE[0] = addressType?.label as string;

        return SUB_TITLE as SubFormHeaderSubLevelType;
    }
}
