<ng-container [formGroup]="$any(controlContainer.control)">
    <wdx-form-field
        [locked]="formElement.isLocked"
        [label]="formElement.label || '' | translateTokenisedString"
        [guidanceText]="formElement.guidanceText || ''"
        [isSensitive]="formElement.isSensitive"
        [isRequired]="formElement.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage : formElement)
                : ''
        "
        [warningMessage]="warningText"
    >
        <wdx-input-group
            [prefix]="!!formElement.prefix"
            [suffix]="!!formElement.suffix"
            *ngIf="INPUT_GROUP_TYPES.includes($any(formElement.fieldType))"
        >
            <span *ngIf="formElement.prefix" prefix>{{
                formElement.prefix
            }}</span>

            <input
                wdxInputText
                type="text"
                *ngIf="formElement.fieldType === FORM_FIELD_TYPE.Text"
                [formControlName]="$any(formElement.name)"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [attr.placeholder]="formElement.placeholder"
                [attr.required]="formElement.isRequired"
                [attr.maxlength]="formElement.max || 100"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement.pendingChange?.status)
                    )
                "
            />

            <input
                wdxInputText
                type="password"
                *ngIf="formElement.fieldType === FORM_FIELD_TYPE.Password"
                [formControlName]="$any(formElement.name)"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [attr.placeholder]="formElement.placeholder"
                [attr.required]="formElement.isRequired"
                [attr.maxlength]="formElement.max || 100"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement.pendingChange?.status)
                    )
                "
            />

            <input
                wdxInputText
                currencyMask
                type="text"
                inputmode="numeric"
                *ngIf="formElement.fieldType === FORM_FIELD_TYPE.Integer"
                [formControlName]="$any(formElement.name)"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [attr.placeholder]="formElement.placeholder"
                [attr.required]="formElement.isRequired"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement.pendingChange?.status)
                    )
                "
                [attr.maxlength]="formElement.max || 100"
                [options]="currencyMaskNumberConfigOverride"
            />

            <input
                wdxInputText
                currencyMask
                type="text"
                inputmode="decimal"
                *ngIf="formElement.fieldType === FORM_FIELD_TYPE.Decimal"
                [formControlName]="$any(formElement.name)"
                [attr.id]="inputId"
                [attr.data-cy]="'input-' + formElement.name"
                [attr.placeholder]="formElement.placeholder"
                [attr.required]="formElement.isRequired"
                [attr.maxlength]="formElement.max || 100"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement.pendingChange?.status)
                    )
                "
            />

            <span *ngIf="formElement.suffix" suffix>{{
                formElement.suffix
            }}</span>
        </wdx-input-group>

        <input
            *ngIf="formElement.fieldType === FORM_FIELD_TYPE.Time"
            wdxInputText
            wdxInputTime
            type="time"
            [formControlName]="$any(formElement.name)"
            [attr.id]="inputId"
            [attr.data-cy]="'input-' + formElement.name"
            [attr.placeholder]="formElement.placeholder"
            [attr.required]="formElement.isRequired"
            [wdxUtilsDisabled]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement.pendingChange?.status)
                )
            "
        />

        <input
            *ngIf="formElement.fieldType === FORM_FIELD_TYPE.Date"
            wdxInputText
            wdxInputDate
            type="date"
            max="9999-12-31"
            [formControlName]="$any(formElement.name)"
            [attr.id]="inputId"
            [attr.data-cy]="'input-' + formElement.name"
            [attr.placeholder]="formElement.placeholder"
            [attr.required]="formElement.isRequired"
            [wdxUtilsDisabled]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement.pendingChange?.status)
                )
            "
        />

        <input
            *ngIf="formElement.fieldType === FORM_FIELD_TYPE.DateTime"
            type="datetime-local"
            wdxInputText
            wdxInputDate
            max="9999-12-31"
            [formControlName]="$any(formElement.name)"
            [attr.id]="inputId"
            [attr.data-cy]="'input-' + formElement.name"
            [attr.placeholder]="formElement.placeholder"
            [attr.required]="formElement.isRequired"
            [wdxUtilsDisabled]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement.pendingChange?.status)
                )
            "
        />

        <div
            *ngIf="formElement.fieldType === FORM_FIELD_TYPE.UrlLookup"
            class="input-group"
        >
            <div class="input-group-text">
                <wdx-icon icon="globe"></wdx-icon>
            </div>
            <input
                type="text"
                wdxInputText
                [attr.data-cy]="'input-' + formElement.name"
                [placeholder]="formElement.placeholder"
                [formControlName]="$any(formElement.name)"
                [attr.required]="formElement.isRequired"
                [wdxUtilsDisabled]="
                    isDisabled ||
                    PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement.pendingChange?.status)
                    )
                "
            />
        </div>

        <ng-container
            *ngIf="formElement.fieldType === FORM_FIELD_TYPE.TextDropdown"
            [formGroupName]="$any(formElement.name)"
        >
            <div class="input-group">
                <input
                    type="text"
                    wdxInputText
                    formControlName="text"
                    [attr.data-cy]="'input-' + formElement.name"
                    [attr.required]="formElement.isRequired"
                    [wdxUtilsDisabled]="
                        isDisabled ||
                        PENDING_CHANGES_LOCK_FIELD.includes(
                            $any(formElement.pendingChange?.status)
                        )
                    "
                />
                <ng-select
                    formControlName="dropdown"
                    bindLabel="label"
                    bindValue="value"
                    placeholder="Select"
                    [attr.data-cy]="'dropdown-' + formElement.name"
                    [items]="(options$ | ngrxPush) || []"
                    [readonly]="
                        isDisabled ||
                        PENDING_CHANGES_LOCK_FIELD.includes(
                            $any(formElement.pendingChange?.status)
                        )
                    "
                >
                </ng-select>
            </div>
        </ng-container>

        <textarea
            *ngIf="formElement.fieldType === FORM_FIELD_TYPE.MultilineText"
            wdxInputText
            cols="30"
            rows="4"
            [attr.data-cy]="'input-' + formElement.name"
            [formControlName]="$any(formElement.name)"
            [attr.id]="inputId"
            [attr.required]="formElement.isRequired"
            [attr.maxlength]="formElement.max || 500"
            [wdxUtilsDisabled]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement.pendingChange?.status)
                )
            "
        ></textarea>
    </wdx-form-field>
</ng-container>
