<ng-container
    *ngIf="{
        disabled:
            isDisabled ||
            formElement?.pendingChange?.status ===
                PENDING_CHANGE_STATUS_TYPE.Submitted
    } as context"
>
    <ng-container
        *ngIf="isSmartView && !context.disabled; else manualInputTemplate"
    >
        <div class="grid" [formGroup]="$any(formControl)">
            <wdx-form-field
                *ngIf="
                    $any(
                        addressTypeService?.addressTypeSchema?.options?.length
                    ) > 1
                "
                class="mb-3 d-block g-col-12 g-col-lg-6"
                [locked]="addressTypeService.addressTypeSchema?.isLocked"
                [label]="
                    addressTypeService.addressTypeSchema?.label
                        | translateTokenisedString
                "
                [guidanceText]="
                    addressTypeService.addressTypeSchema?.guidanceText
                "
                [isSensitive]="
                    addressTypeService.addressTypeSchema?.isSensitive
                "
                [isRequired]="addressTypeService.addressTypeSchema?.isRequired"
                [labelForId]="'smartAddressAddressType'"
                [validationErrorMessage]="
                    formControl?.touched
                        ? (formControl?.errors
                          | validationErrorMessage : formElement)
                        : ''
                "
                [warningMessage]="warningText"
            >
                <ng-select
                    class="select-options-wrap"
                    bindLabel="label"
                    bindValue="value"
                    [attr.data-cy]="
                        'dropdown-' + addressTypeService.addressTypeSchema?.name
                    "
                    [items]="$any(addressTypeService.addressTypeSchema.options)"
                    [formControlName]="
                        $any(addressTypeService.addressTypeSchema?.name)
                    "
                ></ng-select>
            </wdx-form-field>
        </div>

        <wdx-form-field
            [locked]="formElement?.isLocked"
            [label]="formElement?.label | translateTokenisedString"
            [guidanceText]="formElement?.guidanceText"
            [isSensitive]="formElement?.isSensitive"
            [isRequired]="formElement?.isRequired"
            [labelForId]="inputId"
            [validationErrorMessage]="
                formControl?.touched
                    ? (formControl?.errors
                      | validationErrorMessage : formElement)
                    : ''
            "
            [warningMessage]="warningText"
            data-cy="ff-smart-address"
        >
            <button
                *ngIf="
                    !isDisabled ||
                    !PENDING_CHANGES_LOCK_FIELD.includes(
                        $any(formElement?.pendingChange?.status)
                    )
                "
                formFieldActionButton
                class="ms-2"
                data-cy="action-smart-input"
                [wdxButton]="WdxButtonStyle.Link"
                (click)="onToggleView()"
            >
                Manual Input
            </button>
            <div class="input-group">
                <div *ngIf="!addressSelect.hasValue" class="input-group-text">
                    <wdx-icon icon="search"></wdx-icon>
                </div>
                <ng-select
                    #addressSelect
                    class="flex-grow-1"
                    bindLabel="displayText"
                    bindValue="identifier"
                    typeToSearchText="Search for an address"
                    notFoundText="No Addresses found"
                    data-cy="dropdown-address"
                    [typeahead]="addressInput$"
                    [trackByFn]="trackByFn"
                    [loading]="$any(addressSearchResultsIsLoading$ | ngrxPush)"
                    [items]="$any(addressSearchResults$ | ngrxPush)"
                    (change)="onLookupSelected($event)"
                ></ng-select>
            </div>

            <ng-container *ngIf="hasValue$ | ngrxPush">
                <div
                    class="card card-body d-flex flex-row align-items-center justify-content-between mt-2"
                >
                    <address class="mb-0" data-cy="address-result">
                        <span
                            class="d-block"
                            *ngFor="
                                let addressField of smartAddressFields$
                                    | ngrxPush
                            "
                            [innerHtml]="addressField"
                        >
                        </span>
                    </address>

                    <button
                        *ngIf="!isDisabled"
                        size="sm"
                        data-cy="btn-clear"
                        (click)="onLookupClear()"
                        [wdxButton]="WdxButtonStyle.Link"
                        [wdxIconButton]="true"
                    >
                        <wdx-icon icon="trash-alt"></wdx-icon>
                    </button>
                </div>
            </ng-container>
        </wdx-form-field>
    </ng-container>
</ng-container>

<ng-template #manualInputTemplate>
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        data-cy="ff-manual-address"
    >
        <div class="d-flex" formFieldActionButton>
            <button
                *ngIf="!isDisabled && addressLookupEnabled"
                [wdxButton]="WdxButtonStyle.Link"
                class="ms-2"
                data-cy="action-manual-input"
                (click)="onToggleView()"
            >
                Smart Input
            </button>
        </div>

        <wdx-ff-validation-summary
            *ngIf="
                formControl?.invalid && validationSummary$ | ngrxPush;
                let validationSummary
            "
            class="d-block mb-3"
            message="Address contains missing values or validation errors"
            [validationSummary]="validationSummary"
            (errorClicked)="onErrorClicked($event)"
        ></wdx-ff-validation-summary>

        <ng-container [formGroup]="$any(formControl)">
            <div class="card card-muted pt-3">
                <div *ngIf="layoutAndDefinitionEntry" class="card-body grid">
                    <ng-container
                        *ngFor="
                            let formElement of layoutAndDefinitionEntry?.[0]?.layoutAndDefinition;
                            trackBy: $any(trackByFn)
                        "
                    >
                        <ng-container *ngIf="!formElement.isHidden">
                            <div
                                *ngIf="$any(formElement).forcesNewRow"
                                class="g-col-12"
                            ></div>
                            <div
                                class="g-col-12 input form-input"
                                [ngClass]="{
                                    'g-col-lg-6':
                                        !$any(formElement).forcesFullWidth
                                }"
                                [attr.data-form-control]="formElement.name"
                            >
                                <wdx-ff-controls-mapper
                                    [formElement]="formElement"
                                    [attr.data-cy]="
                                        'ff-manual-address-' + formElement.name
                                    "
                                ></wdx-ff-controls-mapper>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </wdx-form-field>
</ng-template>
