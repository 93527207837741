import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../../services/config.service';
import { Params } from '@angular/router';

const CONSENT_WINDOW_KEY = 'consent-window';

@Injectable({ providedIn: 'root' })
export class ConsentService {
    private openedWindow: Window | null = null;

    constructor(private http: HttpClient, private config: ConfigService) {}

    setConsent(params: Params): Observable<string> {
        return this.http.post<string>(
            `${this.config.getConfiguration().API_BASE}/consent`,
            params
        );
    }

    setConsentWindow(window: Window): void {
        this.openedWindow = window;
        const checkConsentInterval = setInterval(() => {
            if (localStorage.getItem(CONSENT_WINDOW_KEY) === 'true') {
                localStorage.removeItem(CONSENT_WINDOW_KEY);
                clearInterval(checkConsentInterval);
                if (this.openedWindow) {
                    this.openedWindow.close();
                    this.openedWindow = null;
                }
            }
        }, 3000);
    }

    closeConsentWindow(): void {
        localStorage.setItem(CONSENT_WINDOW_KEY, 'true');
    }
}
