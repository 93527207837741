<atom-modal-wrapper [modalId]="modalId" size="xl" [modalTemplate]="dynamicForm">
    <ng-template #dynamicForm>
        <organism-modal
            [modalTitle]="
                isLoading
                    ? 'Loading...'
                    : (formTitle | translateTokenisedString)
            "
        >
            <atom-async-data
                [isLoading]="isLoading"
                [throbberContainerStyle]="false"
            >
                <wdx-ff-form-container
                    *ngIf="formId"
                    [formTemplateId]="formTemplateId"
                    [formTemplateEntityId]="formTemplateEntityId"
                    [formTemplateEntityType]="formTemplateEntityType"
                    [formId]="formId"
                    [entityId]="entityId"
                    [appId]="appId"
                    [initialisationParams]="initialisationParams"
                    [queryParams]="queryParams"
                    [emitInsteadOfPost]="emitInsteadOfPost"
                    [emitData]="emitData"
                    [customEndpoint]="customEndpoint"
                    [readonlyMode]="readonlyMode"
                    (formTitleReceived)="onFormTitleReceived($event)"
                ></wdx-ff-form-container>

                <atom-feature-svg
                    *ngIf="!formId"
                    class="d-block"
                    size="lg"
                    [svg]="FEATURE_SVG.AccessDenied"
                >
                    <h5 class="mb-2">We were unable to locate your form</h5>
                    <div>Please contact your system administrator</div>
                </atom-feature-svg>
            </atom-async-data>
        </organism-modal>
    </ng-template>
</atom-modal-wrapper>
