<ng-container
    *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Select"
    [formGroup]="$any(controlContainer?.control)"
>
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [legend]="formElement?.label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage : formElement)
                : ''
        "
        [warningMessage]="warningText || ''"
    >
        <ng-container
            *ngIf="formElement?.elementStyle === FORM_ELEMENT_STYLE.Checkboxes"
        >
            <ng-container
                *ngFor="let option of options$ | ngrxPush; trackBy: trackByFn"
            >
                <wdx-checkbox
                    [label]="option.label || ''"
                    [value]="option.value"
                    [checked]="formControl?.value?.includes(option.value)"
                    [disabled]="
                        isDisabled ||
                        PENDING_CHANGES_LOCK_FIELD.includes(
                            $any(formElement?.pendingChange?.status)
                        )
                    "
                    (isChecked)="onCheckboxChange($event, option.value)"
                ></wdx-checkbox>
            </ng-container>
        </ng-container>

        <ng-container
            *ngIf="formElement?.elementStyle === FORM_ELEMENT_STYLE.CheckTiles"
        >
            <wdx-checkbox-tile-group>
                <ng-container
                    *ngFor="
                        let option of options$ | ngrxPush;
                        trackBy: trackByFn
                    "
                >
                    <wdx-checkbox-tile
                        [label]="option.label"
                        [value]="option.value"
                        [checked]="formControl?.value?.includes(option.value)"
                        [disabled]="
                            isDisabled ||
                            PENDING_CHANGES_LOCK_FIELD.includes(
                                $any(formElement?.pendingChange?.status)
                            )
                        "
                        (isChecked)="onCheckboxChange($event, option.value)"
                    >
                        <wdx-icon-stack *ngIf="option.iconStack" icon>
                            <wdx-icon
                                *ngFor="let icon of option.iconStack"
                                [icon]="
                                    icon.icon +
                                    ' fa-2x fa-stack-1x ' +
                                    icon.style
                                "
                                [rotate]="icon?.rotate"
                                [offsetTop]="icon?.offsetTop"
                                [offsetLeft]="icon?.offsetLeft"
                                [scale]="icon?.scale"
                            ></wdx-icon>
                        </wdx-icon-stack>

                        <wdx-icon
                            *ngIf="option.icon"
                            icon
                            [icon]="option.icon + ' fa-2x'"
                        ></wdx-icon>
                    </wdx-checkbox-tile>
                </ng-container>
            </wdx-checkbox-tile-group>
        </ng-container>
    </wdx-form-field>
</ng-container>
