<ng-container *ngIf="formElement?.fieldType === FORM_FIELD_TYPE.Select">
    <ng-container [ngSwitch]="formElement?.elementType">
        <ng-container *ngSwitchCase="FORM_ELEMENT_TYPE.Field">
            <ng-container [ngSwitch]="formElement?.elementStyle">
                <ng-container [formGroup]="$any(controlContainer?.control)">
                    <wdx-form-field
                        *ngSwitchCase="FORM_ELEMENT_STYLE.Radios"
                        [locked]="formElement?.isLocked"
                        [label]="formElement?.label | translateTokenisedString"
                        [guidanceText]="
                            formElement?.guidanceText | translateTokenisedString
                        "
                        [isSensitive]="formElement?.isSensitive"
                        [isRequired]="formElement?.isRequired"
                        [validationErrorMessage]="
                            formControl?.touched
                                ? (formControl?.errors
                                  | validationErrorMessage : formElement)
                                : ''
                        "
                        [warningMessage]="warningText"
                    >
                        <wdx-radio
                            *ngFor="
                                let option of options$ | ngrxPush;
                                let i = index
                            "
                            [label]="option.label"
                            [labelForId]="inputId"
                            [value]="option.value"
                        >
                            <input
                                type="radio"
                                class="form-check-input"
                                [formControlName]="$any(formElement.name)"
                                [value]="option.value"
                                [attr.data-cy]="
                                    'option-' +
                                    formElement.name +
                                    '-' +
                                    option.value
                                "
                                [wdxUtilsDisabled]="
                                    isDisabled ||
                                    formElement?.pendingChange?.status ===
                                        PENDING_CHANGE_STATUS_TYPE.Submitted
                                "
                            />
                        </wdx-radio>
                    </wdx-form-field>

                    <wdx-form-field
                        *ngSwitchCase="FORM_ELEMENT_STYLE.RadioTiles"
                        [locked]="formElement?.isLocked"
                        [label]="formElement?.label | translateTokenisedString"
                        [guidanceText]="formElement?.guidanceText"
                        [isSensitive]="formElement?.isSensitive"
                        [isRequired]="formElement?.isRequired"
                        [validationErrorMessage]="
                            formControl?.touched
                                ? (formControl?.errors
                                  | validationErrorMessage : formElement)
                                : ''
                        "
                    >
                        <wdx-radio-tile-group>
                            <wdx-radio-tile
                                *ngFor="
                                    let option of options$ | ngrxPush;
                                    let i = index
                                "
                                [label]="option.label"
                                [labelForId]="inputId"
                                [value]="option.value"
                            >
                                <input
                                    type="radio"
                                    [formControlName]="$any(formElement.name)"
                                    [value]="option.value"
                                    [attr.data-cy]="'option-' + option.value"
                                    [wdxUtilsDisabled]="
                                        isDisabled ||
                                        formElement?.pendingChange?.status ===
                                            PENDING_CHANGE_STATUS_TYPE.Submitted
                                    "
                                />

                                <wdx-icon-stack *ngIf="option.iconStack" icon>
                                    <wdx-icon
                                        *ngFor="let icon of option.iconStack"
                                        [icon]="
                                            icon.icon +
                                            ' fa-2x fa-stack-1x ' +
                                            icon.style
                                        "
                                        [rotate]="icon?.rotate"
                                        [offsetTop]="icon?.offsetTop"
                                        [offsetLeft]="icon?.offsetLeft"
                                        [scale]="icon?.scale"
                                    ></wdx-icon>
                                </wdx-icon-stack>

                                <wdx-icon
                                    *ngIf="option.icon"
                                    icon
                                    [icon]="option.icon + ' fa-2x'"
                                ></wdx-icon>
                            </wdx-radio-tile>
                        </wdx-radio-tile-group>
                    </wdx-form-field>

                    <wdx-form-field
                        *ngSwitchCase="FORM_ELEMENT_STYLE.ButtonGroup"
                        [locked]="formElement?.isLocked"
                        [label]="formElement?.label | translateTokenisedString"
                        [guidanceText]="formElement?.guidanceText"
                        [isSensitive]="formElement?.isSensitive"
                        [isRequired]="formElement?.isRequired"
                    >
                        <wdx-radio-group-form-control
                            [buttonsConfig]="$any(options$ | ngrxPush)"
                            [formControlName]="$any(formElement.name)"
                        ></wdx-radio-group-form-control>
                    </wdx-form-field>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>
</ng-container>
