<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        class="h-100"
        [locked]="formElement?.isLocked"
        [label]="label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="inputId"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage : formElement)
                : ''
        "
        [warningMessage]="warningText"
    >
        <div *ngIf="documents">
            <div class="form-control p-0">
                <wdx-document-info
                    *ngFor="let document of documents; let index = index"
                    class="d-block w-100 border-bottom"
                    [subtitle]="$any(document.fileIndex) | fileName"
                    [label]="document?.name"
                    [fileIndex]="document.fileIndex"
                >
                    <ng-container *ngIf="!isDisabled">
                        <wdx-icon-button
                            class="ms-2"
                            size="sm"
                            wdxStyle="outline-primary"
                            (wdxOnClick)="onDeleteDocument(document)"
                            ><wdx-icon icon="trash-alt"></wdx-icon
                        ></wdx-icon-button>
                        <wdx-icon-button
                            class="ms-2"
                            size="sm"
                            wdxStyle="outline-primary"
                            (wdxOnClick)="
                                editDocument({
                                    isQuickCreate: true,
                                    preserveActiveModal: true,
                                    formId: DOCUMENT_FORM_ID,
                                    entityId: document.id
                                })
                            "
                            ><wdx-icon icon="pen"></wdx-icon></wdx-icon-button
                    ></ng-container>
                </wdx-document-info>

                <div *ngIf="!isDisabled" class="p-4">
                    <ng-container
                        *ngIf="documents?.length === formElement?.max"
                        class="text-center body-text-medium"
                    >
                        A maximum of {{ formElement?.max }} documents can be
                        added.
                    </ng-container>
                    <ng-container
                        *ngIf="$any(documents?.length) < $any(formElement?.max)"
                    >
                        <div class="text-center body-text-medium mb-2">
                            Click to add
                            {{ documents?.length ? 'another' : 'a' }}
                            document
                        </div>
                        <div class="d-flex justify-content-center">
                            <wdx-icon-button
                                *ngIf="
                                    addDocumentFormSetup$
                                        | ngrxPush as addDocumentFormSetup
                                "
                                class="ms-2"
                                size="sm"
                                data-cy="btn-add-document"
                                wdxStyle="outline-primary"
                                (wdxOnClick)="addDocument(addDocumentFormSetup)"
                                ><wdx-icon [icon]="UTILS_ICON_ADD"></wdx-icon
                            ></wdx-icon-button></div
                    ></ng-container>
                </div>
            </div>
        </div>
    </wdx-form-field>
</ng-container>
