import { Injectable } from '@angular/core';
import { SystemEntity } from '../../models/shared-api-models';
import { EnumTranslationTypes } from './translations.constants';

export type Translations = Record<string, string>;

@Injectable({
    providedIn: 'root',
})
export class TranslationsService {
    private translations!: Translations;

    /**
     * Sets the translation data from api (primarily from app guard)
     */
    set(translations: Translations) {
        this.translations = translations;
    }

    /**
     * Returns the entire translations object
     */
    get() {
        return this.translations;
    }

    /**
     * Returns a specific translation by key
     * @param key The translation key
     * @param replacerValue Replacer value if the key contains a pattern
     */
    getTranslationByKey(key: string, replacerValue?: string): string {
        const label = this.translations ? this.translations[key] : key;
        return replacerValue ? label.replace(/{value}/g, replacerValue) : label;
    }

    /**
     * Returns the tranlsation of a given entity type
     * @param entityType
     * @param plural
     */

    getTranslatedEntityType(entityType: SystemEntity, plural = false) {
        const translationKey = plural
            ? `ENTITY_${entityType?.toUpperCase()}_PLURAL`
            : `ENTITY_${entityType?.toUpperCase()}_SINGULAR`;

        return translationKey
            ? this.getTranslationByKey(translationKey)
            : entityType;
    }

    /**
     * Returns the tranlsation of a given entity type
     * @param value
     * @param type
     */

    getTranslatedEnumType(value: string, type: EnumTranslationTypes) {
        const translationKey = `ENUM_${type}_${value?.toUpperCase()}`;

        return translationKey
            ? this.getTranslationByKey(translationKey)
            : value;
    }

    translateTokenisedString(value: string) {
        return value.replace(
            /{(.*?)}/g,
            (match, translationKey) =>
                this.getTranslationByKey(translationKey) || match
        );
    }
}
