<clmi-simple-view
    [config]="config"
    (formFiltersEvent)="formFiltersEvent.emit($event)"
    *ngIf="!(isQueryBuilderVisible$ | ngrxPush)"
></clmi-simple-view>
<clmi-query-builder
    [config]="config"
    (formFiltersEvent)="formFiltersEvent.emit($event)"
    *ngIf="isQueryBuilderVisible$ | ngrxPush"
></clmi-query-builder>
