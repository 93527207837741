import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { View } from '@wdx/clmi/api-models';
import { FeatureFlag } from '@wdx/clmi/api-services/services';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { filter, takeUntil } from 'rxjs/operators';
import {
    ICON_CLONE,
    ICON_EDIT,
} from '../../../../../../constants/icons.constants';
import { DialogMode } from '../../../../../../models/dialog.model';
import { DialogService } from '../../../../../../services/dialog.service';
import { ViewsFacadeService } from '../../../../../../state/views/views-facade.service';
import {
    FormFiltersConfig,
    FormFiltersEvent,
    FormFiltersEventType,
} from '../../../form-filters.component';
import {
    FilterQueryService,
    FilterViewFacadeService,
    QuerySectionsFacadeService,
} from '../../services';

@Component({
    selector: 'clmi-filter-view-list',
    templateUrl: './view-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewListComponent extends WdxDestroyClass implements OnInit {
    readonly ICON_EDIT = ICON_EDIT.icon;
    readonly ICON_CLONE = ICON_CLONE.icon;
    readonly personal = 'personal';
    readonly FEATURE_FLAG_CUSTOM_VIEWS = FeatureFlag.CustomViews;
    showMore = false;
    records = 4;
    viewToDelete: View;

    @Input() icon: string;
    @Input() title: string;
    @Input() views: View[];
    @Input() viewsType: 'personal' | 'system';
    @Input() config: FormFiltersConfig = {};

    @Output() formFiltersEvent = new EventEmitter<FormFiltersEvent>();

    constructor(
        public filterQueryService: FilterQueryService,
        private querySectionsFacadeService: QuerySectionsFacadeService,
        private dialogService: DialogService,
        private viewsFacadeService: ViewsFacadeService,
        private filterViewFacadeService: FilterViewFacadeService
    ) {
        super();
    }

    ngOnInit(): void {
        this.viewsFacadeService
            .deleteViewSuccess$()
            .pipe(
                takeUntil(this.destroyed$),
                filter(
                    (action) =>
                        !action || action.viewId === this.viewToDelete?.id
                )
            )
            .subscribe((_) => {
                this.filterViewFacadeService.viewsPersonal$.next(
                    this.views.filter(
                        (view) => view.id !== this.viewToDelete.id
                    )
                );

                if (
                    this.viewToDelete.id ===
                    this.filterQueryService?.view$.value?.id
                ) {
                    if (!this.config.emitEventOnly) {
                        this.filterQueryService.selectView(
                            this.filterViewFacadeService.viewsSystem$.value[0]
                        );
                    }
                    this.formFiltersEvent.emit({
                        type: FormFiltersEventType.ViewSelected,
                        view: this.filterViewFacadeService.viewsSystem$
                            .value[0],
                    });
                }

                this.viewToDelete = null;
            });
    }

    trackByViewId(_: number, view: View): string {
        return view.id;
    }

    updateSelectedView(view: View): void {
        if (!this.config.emitEventOnly) {
            this.filterQueryService.selectView(view);
            this.filterQueryService.updateTableHeader$.next({
                isFavourite: false,
            });
        }
        this.formFiltersEvent.emit({
            type: FormFiltersEventType.ViewSelected,
            view,
        });
    }

    toggle(): void {
        this.showMore = !this.showMore;
    }

    toggleQueryBuilder(view: View): void {
        this.filterQueryService.toggleEditView(view);
        this.querySectionsFacadeService.toggleQueryBuilder();
        this.formFiltersEvent.emit({
            type: FormFiltersEventType.ViewSelected,
            view,
        });
    }

    deletePersonalView(viewToDelete: View): void {
        this.viewToDelete = viewToDelete;

        this.dialogService.open({
            dialogMode: DialogMode.DeleteView,
            data: this.viewToDelete,
            confirmAction: this.viewsFacadeService.deleteViewAction(
                this.filterQueryService.viewType,
                this.viewToDelete?.id
            ),
        });
    }
}
