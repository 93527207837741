<wdx-form-field
    [locked]="formElement?.isLocked"
    [label]="formElement?.label | translateTokenisedString"
    [guidanceText]="formElement?.guidanceText"
    [isSensitive]="formElement?.isSensitive"
    [isRequired]="formElement?.isRequired"
    [labelForId]="inputId"
    [validationErrorMessage]="
        formControl?.touched
            ? (formControl?.errors | validationErrorMessage : formElement)
            : ''
    "
    [warningMessage]="warningText"
>
    <wdx-document-info
        *ngIf="fileIndex; else fileUpload"
        class="d-block mb-1 w-100"
        [fileIndex]="fileIndex"
    >
        <wdx-icon-button
            *ngIf="!isDisabled"
            class="ms-2"
            size="sm"
            wdxStyle="outline-primary"
            (wdxOnClick)="onDeleteFile(fileIndex)"
            ><wdx-icon icon="trash-alt"></wdx-icon
        ></wdx-icon-button>
    </wdx-document-info>

    <ng-template #fileUpload>
        <wdx-file-upload
            data-cy="document-upload"
            [disabled]="isDisabled ? true : false"
            [imageUpload]="
                formElement.fieldType === FORM_FIELD_TYPE.ImageUpload
            "
            [customEndpoint]="formElement?.customEndpoint"
            (fileUploaded)="onFileUploaded($event)"
        ></wdx-file-upload>
    </ng-template>
</wdx-form-field>
