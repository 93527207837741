import {
    ChangeDetectionStrategy,
    Component,
    Input,
    EventEmitter,
    Output,
} from '@angular/core';
import { View } from '@wdx/clmi/api-models';
import { WdxDestroyClass } from '@wdx/shared/utils';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuerySectionsFacadeService } from './shared/services/query-sections-facade/query-sections-facade.service';

export interface FormFiltersConfig {
    emitEventOnly?: boolean;
}

export enum FormFiltersEventType {
    ViewSelected,
    ViewSaved,
    ApplyFilter,
}

export interface FormFiltersEvent {
    type: FormFiltersEventType;
    view?: View;
}

@Component({
    selector: 'clmi-form-filters',
    templateUrl: './form-filters.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [QuerySectionsFacadeService],
})
export class FormFiltersComponent extends WdxDestroyClass {
    @Input() config: FormFiltersConfig = {};

    @Output() formFiltersEvent = new EventEmitter<FormFiltersEvent>();

    get isQueryBuilderVisible$(): Observable<boolean> {
        return this.querySectionsFacadeService.showBuilder$.pipe(
            takeUntil(this.destroyed$)
        );
    }

    constructor(
        private querySectionsFacadeService: QuerySectionsFacadeService
    ) {
        super();
    }
}
