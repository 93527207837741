import { inject, Injectable } from '@angular/core';
import { SitemapEntry } from '@wdx/clmi/api-models';
import { RouteFacade } from '@wdx/clmi/api-services/state';
import { ROUTE_PREFIX, ROUTE_PREFIX_ADMINISTRATION } from '@wdx/shared/utils';
import { BehaviorSubject, combineLatest, map, Observable, take } from 'rxjs';
import { NavigationMenu } from '../../../models/navigation-menu.model';

export interface RouterConfig {
    configs: SitemapEntry[];
    isAdminArea: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    private routeFacade = inject(RouteFacade);

    private _navMenu: NavigationMenu[];

    private _siteMap: BehaviorSubject<SitemapEntry[]> = new BehaviorSubject(
        undefined
    );

    public siteMap$ = this._siteMap.asObservable();

    private _adminSiteMap: BehaviorSubject<SitemapEntry[]> =
        new BehaviorSubject(undefined);

    public adminSiteMap$ = this._adminSiteMap.asObservable();

    private _adminNavMenu: BehaviorSubject<NavigationMenu[]> =
        new BehaviorSubject(undefined);

    public adminNavMenu$ = this._adminNavMenu.asObservable();

    public get navMenu(): NavigationMenu[] {
        return this._navMenu;
    }

    public setSiteMap(siteMap: SitemapEntry[]): void {
        this._siteMap.next(siteMap);
        this._navMenu = siteMap.map((menuItem) =>
            this.createNavigationMenu(menuItem)
        );
    }

    public setAdminSiteMap(adminSiteMap: SitemapEntry[]): void {
        this._adminSiteMap.next(adminSiteMap);
        this._adminNavMenu.next(
            adminSiteMap.map((menuItem) =>
                this.createNavigationMenu(
                    menuItem,
                    null,
                    ROUTE_PREFIX_ADMINISTRATION[0]
                )
            )
        );
    }

    public getCurrentConfig$(): Observable<RouterConfig> {
        return combineLatest({
            adminSiteMap: this.adminSiteMap$,
            siteMap: this.siteMap$,
            path: this.routeFacade.getPath$(),
        }).pipe(
            map(({ adminSiteMap, path, siteMap }) => {
                const adminArea = this.isAdminArea(path);
                const pathParts = adminArea ? path.slice(2) : path.slice(1);
                const currentSiteMap = adminArea ? adminSiteMap : siteMap;
                const currentConfig: SitemapEntry[] = [];
                pathParts.forEach((part, index) => {
                    if (index === 0) {
                        currentConfig.push(
                            currentSiteMap.find((entry) => entry.code === part)
                        );
                    } else {
                        currentConfig.push(
                            currentConfig[index - 1]?.children?.find(
                                (entry) => entry.code === part
                            )
                        );
                    }
                });
                return {
                    configs: currentConfig,
                    isAdminArea: true,
                };
            })
        );
    }

    public isAdminArea$() {
        return this.routeFacade.getPath$().pipe(
            take(1),
            map((path) => this.isAdminArea(path))
        );
    }

    private isAdminArea(path: string[]): boolean {
        return path[1] === ROUTE_PREFIX_ADMINISTRATION[0];
    }

    private createNavigationMenu(
        menuItem: SitemapEntry,
        parentItem?: SitemapEntry,
        basePath?: string
    ): NavigationMenu {
        const linkPrefix = basePath
            ? [...ROUTE_PREFIX, basePath]
            : [...ROUTE_PREFIX];

        return {
            id: menuItem.code,
            pageType: menuItem.pageType,
            translationKey: menuItem?.displayName?.key,
            ...(menuItem?.icon ? { icon: menuItem?.icon } : {}),
            routerLink: parentItem
                ? [...linkPrefix, parentItem.code, menuItem.code]
                : [...linkPrefix, menuItem.code],
            cySelector: menuItem.code,
            disabledBy: [],
            ...(menuItem?.children?.length
                ? {
                      menu: menuItem?.children.map((child) =>
                          this.createNavigationMenu(child, menuItem, basePath)
                      ),
                  }
                : {}),
        };
    }
}
