import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { ConsentService } from '../../services/consent';
import { Severity } from '@wdx/shared/utils';
import { WdxToastService } from '@wdx/shared/components/wdx-toast';
import { of } from 'rxjs';

export const consentGuard: CanActivateFn = (route, state) => {
    const wdxToastService = inject(WdxToastService);
    const consentService = inject(ConsentService);
    const queryParams = route.queryParams;
    const severity = queryParams?.error ? Severity.Danger : Severity.Success;
    const message = queryParams?.error
        ? `Error consent ${queryParams.error.toLowerCase()} ${queryParams.error_subcode.toLowerCase()}`
        : 'Access granted successfully';
    wdxToastService.show({
        body: [message],
        severity,
    });
    return consentService.setConsent(queryParams).pipe(
        map(() => {
            consentService.closeConsentWindow();
            return false;
        }),
        catchError(() => {
            consentService.closeConsentWindow();
            return of(false);
        })
    );
};
