<ng-container [formGroup]="$any(controlContainer?.control)">
    <wdx-form-field
        [locked]="formElement?.isLocked"
        [label]="formElement?.label | translateTokenisedString"
        [guidanceText]="formElement?.guidanceText"
        [isSensitive]="formElement?.isSensitive"
        [isRequired]="formElement?.isRequired"
        [labelForId]="selectId"
        [validationErrorMessage]="
            formControl?.touched
                ? (formControl?.errors | validationErrorMessage : formElement)
                : ''
        "
        [warningMessage]="warningText"
    >
        <ng-select
            class="select-options-wrap"
            bindLabel="label"
            bindValue="value"
            [attr.data-cy]="'dropdown-' + formElement?.name"
            [multiple]="formElement?.fieldType === FORM_FIELD_TYPE.Multiselect"
            [compareWith]="$any(compareOptionToValue)"
            [items]="$any(options$ | ngrxPush)"
            [loading]="loading"
            [labelForId]="selectId"
            [readonly]="
                isDisabled ||
                PENDING_CHANGES_LOCK_FIELD.includes(
                    $any(formElement?.pendingChange?.status)
                )
            "
            [formControlName]="$any(formElement?.name)"
        ></ng-select>
    </wdx-form-field>
</ng-container>
